import {AppProvider} from './providers/app';
import {AppRoutes} from './routes';
import './App.css';
import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_SENTRY_ENABLE == 'true') {
    Sentry.init({
        dsn: 'https://030cebd35cfc4565bd83aec533cdde83@o4505498415923200.ingest.sentry.io/4505498474577921',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    'localhost',
                    'https://api.recmii.com',
                    'https://api.staging.recmii.com',
                ],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    });
}

function App() {
    return (
        <AppProvider>
            <AppRoutes />
        </AppProvider>
    );
}

export default App;
