import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {SpinLoading} from 'antd-mobile';

import {lazyImport} from '../utils/lazyImport';
import {ScrollToTop} from 'src/lib/scrollToTop';

const {Proposals} = lazyImport(
    () => import('../features/proposals'),
    'Proposals',
);
const {Proposal} = lazyImport(() => import('../features/proposal'), 'Proposal');

const App = () => {
    return (
        <div style={{backgroundColor: 'lightgray'}}>
            <div
                style={{
                    minHeight: '100vh',
                    maxWidth: '620px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    backgroundColor: 'white',
                }}>
                <ScrollToTop />
                <Suspense
                    fallback={
                        <div
                            style={{
                                display: 'flex',
                                height: '100vh',
                                width: '100vw',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <SpinLoading style={{'--size': '36px'}} />
                        </div>
                    }>
                    <Outlet />
                </Suspense>
            </div>
        </div>
    );
};

export const publicRoutes = [
    {
        path: '/',
        element: <App />,
        children: [
            {path: 'proposals/:jobseekerId', element: <Proposals />},
            {path: 'proposals/:jobseekerId/:proposalId', element: <Proposal />},
        ],
    },
];
